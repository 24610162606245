import React, { useEffect, useState, useContext } from 'react';
import { Box, Flex, Text, Image, Heading } from 'theme-ui';
import TextContent from '../../Text/TextContent';
import EventItem from './EventItem';
import cloudinaryString from '../../../helpers/cloudinaryString';
import NoEvents from './NoEvents';
import { store } from '../../../context/store';
let axios = require('axios');
let jsonpAdapter = require('axios-jsonp');

const Events = props => {
  const { globalState, dispatch } = useContext(store);
  const { businessData, configData, poweredImages, stories } = globalState;
  const gonationID = 'bzn-ZwpxdubGQEuQvv1q88omCw';

  // ==== State
  const [recurringEvents, setRecurringEvents] = useState({
    eventsData: null,
    isLoading: true,
    modalShowing: '',
  });

  const [singleEvents, setSingleEvents] = useState({
    eventsData: null,
    isLoading: true,
    modalShowing: '',
  });

  // ==== Data Manipulation

  // ==== MOUNTING

  useEffect(() => {
    // fetch recurring events
    axios({
      url: `https://data.prod.gonation.com/profile/recurringevents?profile_id=${gonationID}`,
      adapter: jsonpAdapter,
    })
      .then(res => {
        setRecurringEvents({
          ...recurringEvents,
          eventsData: res.data,
          isLoading: false,
        });
      })
      .catch(e => {
        console.log('error : ', console.e);
        setRecurringEvents({ ...recurringEvents, isLoading: false });
      });

    // fetch special events
    axios({
      url: `https://data.prod.gonation.com/profile/events?profile_id=${gonationID}`,
      adapter: jsonpAdapter,
    })
      .then(res => {
        setSingleEvents({
          ...singleEvents,
          eventsData: res.data,
          isLoading: false,
        });
      })
      .catch(e => {
        console.log('error : ', console.e);
        setSingleEvents({ ...recurringEvents, isLoading: false });
      });
  }, []);

  const closeModal = () => {
    // maybe refactor at some point to recieve a type and then close the right one instead of close all
    setSingleEvents({ ...singleEvents, modalShowing: '' });
    setRecurringEvents({ ...recurringEvents, modalShowing: '' });
  };

  return (
    <>
      <Box sx={styles}>
        <TextContent
          title={'Events and Holidays'}
          subtitle={'The Latest At Locanda Vecchia'}
          text={'Check back regularly for new events and upcoming holidays'}
          sx={styles.textContent}
        />

        {!recurringEvents.isLoading &&
        recurringEvents.eventsData &&
        recurringEvents.eventsData.events.length ? (
          // ========== Start RECURRINGS EVENTS JSX ==============
          <Box sx={styles.container} className="eventsOuterContainer container">
            <Text
              sx={styles.heading}
              as="h3"
              className="heading recurring-header"
            >
              Weekly Events
            </Text>
            <Flex
              sx={styles.eventsContainer}
              className="eventsContainer recurringEventsContainer"
            >
              {recurringEvents.eventsData.events.map(event => {
                return (
                  <EventItem
                    key={event._id}
                    event={event}
                    setEvents={setRecurringEvents}
                    closeModal={closeModal}
                    state={recurringEvents}
                    slug={businessData.slug}
                  />
                );
              })}
            </Flex>
          </Box>
        ) : (
          ''
        )}

        {!singleEvents.isLoading &&
        singleEvents.eventsData &&
        singleEvents.eventsData.events.length ? (
          // ========== END OF RECURRINGS EVENTS JSX ============

          // ======= Start of Single Events  =======
          <Box sx={styles.container} className="container">
            <Text sx={styles.heading} as="h3" className="heading">
              Special Events
            </Text>
            <Flex
              sx={styles.eventsContainer}
              className="eventsContainer specialEventsContainer"
            >
              {singleEvents.eventsData.events.map(event => {
                return (
                  <EventItem
                    key={event._id}
                    event={event}
                    setEvents={setRecurringEvents}
                    closeModal={closeModal}
                    state={recurringEvents}
                    slug={businessData.slug}
                  />
                );
              })}
            </Flex>
          </Box>
        ) : (
          // ========== END OF RECURRINGS EVENTS JSX ============
          ''
        )}
      </Box>
      {!singleEvents.isLoading &&
      !recurringEvents.isLoading &&
      !singleEvents.eventsData.length &&
      !recurringEvents.eventsData.length ? (
        <NoEvents
          singleEvents={singleEvents?.eventdata?.events}
          recurringEvents={recurringEvents?.eventsData?.events}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Events;

const styles = {
  // ?============================
  // ?======  Containers =========
  // ?============================
  container: {
    padding: '1rem',
  },

  // ?============================
  // ?======  Text Content =======
  // ?============================

  heading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    textTransform: 'capitalize',
    color: 'primary',
    fontSize: ['2rem', '2rem'],
    textAlign: 'center',
    whiteSpace: 'nowrap',
    marginBottom: '2rem',
    ':before': {
      content: "''",
      height: '2px',
      width: '40%',
      backgroundColor: 'lightgrey',
      marginRight: '3rem',
    },
    ':after': {
      content: "''",
      height: '2px',
      width: '40%',
      backgroundColor: 'lightgrey',
      marginLeft: '3rem',
    },
  },

  textContent: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['2rem', '', '2rem 6rem 0rem'],
    '.title': {
      fontSize: ['3rem'],
    },
    '.subtitle': {
      fontSize: ['2rem'],
    },
  },

  // ?============================
  // ?===== Events Container =====
  // ?============================

  eventsContainer: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  // ?============================
  // ?======  Modal Styles =======
  // ?============================

  modalContainer: {
    position: 'fixed',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    zIndex: '9999',
  },

  modalBackground: {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },

  modal: {
    padding: 0,
    maxWidth: '767px',
    zIndex: '9999',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    color: 'black',
    backgroundColor: 'primary',
    minWidth: '280px',
    border: 'solid 6px white',
  },

  modalImage: {
    maxHeight: ['175px', '250px', '500px'],
    width: '100%',
    objectFit: 'cover',
  },

  modalContentContainer: {
    padding: 3,
    color: 'white',
  },

  modalEventName: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
    textTransform: 'capitalize',
    fontSize: 3,
    marginBottom: 3,
    color: 'white',
    textAlign: 'left',
  },

  modalEventDate: {
    alignItems: 'center',
    marginBottom: 3,
    color: 'light',
  },

  modalEventTime: {
    marginBottom: '1rem',
  },

  datesSeperator: {
    color: 'light',
    marginRight: 2,
    marginLeft: 1,
  },
  modalEventDescription: {
    color: 'light',
  },

  eventCTAS: { flexWrap: 'wrap', marginBottom: '0.5rem' },
  eventCTA: {
    variant: 'buttons.primary',
    margin: '0.5rem 1rem 0.5rem 0rem',
    textDecoration: 'underline',
    fontSize: ['1.25rem', '1.5rem'],
    cursor: 'pointer',
    // ?== Psuedo Elements ==
    ':hover': {
      color: 'primary',
    },
  },
};
