import React from 'react'
import { Box, Text } from 'theme-ui'
import moment from 'moment'
export default function EventDate({ date }) {
  return (
    <Box sx={styles.eventItemDateContainer} className='eventItemDateContainer'>
      <Text sx={styles.eventDate} className='eventDate'>
        {`${moment(date).format('DD')}`}
      </Text>
      <Text sx={styles.eventMonth} className='eventMonth'>
        {`${moment(date).format('MMM')}`}
      </Text>
      <Text sx={styles.eventTime} className='eventTime'>
        {`${moment(date).format('h:mm a')}`}
      </Text>
    </Box>
  )
}

const styles = {
  eventItemDateContainer: {
    textAlign: ['center'],
    padding: '0.25rem 0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  eventDate: {
    fontSize: 4,
    color: 'secondary',
    fontWeight: 'normal',
    width: '100%',
    marginBottom: 0,
    fontFamily: 'body',
    lineHeight: '1',
  },

  eventMonth: {
    textTransform: 'uppercase',
    marginBottom: 0,
    width: '100%',
    fontFamily: 'body',
    lineHeight: '1.5',
    color: 'black',
  },
  eventTime: {
    borderTop: 'solid 1px black',
    textTransform: 'lowercase',
    fontSize: '0.8rem',
    color: 'black',
  },
}
