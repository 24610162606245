import React from 'react'
import moment from 'moment'
import { Box, Image, Flex, Text, Link } from 'theme-ui'
import EventDate from './EventDate'
import EventDays from './EventDays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlassCheers,
  faClock,
  faClipboard,
} from '@fortawesome/free-solid-svg-icons'

export default function EventsModal({
  event,
  modalShowing,
  closeModal,
  variantName,
}) {
  const { name, starts, ends, description, imageurl, ctas } = event

  return modalShowing ? (
    <Box sx={styles.modalContainer} className='modalContainer'>
      <Box
        sx={styles.modalBackground}
        className='modalBackground'
        onClick={() => closeModal()}
      />
      <Box sx={styles.modal} className='modal'>
        <Image
          sx={styles.modalImage}
          src={imageurl}
          className='modalImage'
          alt='Modal_Event_Image'
        />

        <Box
          sx={styles.modalContentContainer}
          className='modalContentContainer'
        >
          <Box sx={styles.dateNameContainer}>
            {!event.eventDays && (
              <EventDate date={starts} variantName={variantName} />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Text sx={styles.modalEventName} className='modalEventName'>
                {name}
              </Text>

              <Text sx={styles.modalEventTime} className='modalEventTime'>
                {!event.eventDays && (
                  <>
                    {`${moment(starts).format('dddd, MMMM D, YYYY')}`} at
                    {` ${moment(starts).format('hh:mm a')}`}
                  </>
                )}
                {event.eventDays && (
                  <>
                    <EventDays
                      eventDays={event.eventDays}
                      variantName={variantName}
                    />
                    <FontAwesomeIcon icon={faClock} />
                    {'  '}
                    {` ${moment(starts).format('hh:mm a')}`}
                  </>
                )}
              </Text>
            </Box>
          </Box>

          <Text
            as='p'
            sx={styles.modalEventDescription}
            className='modalEventDescription'
          >
            {description}
          </Text>

          {ctas ? (
            <Flex sx={styles.eventCTAS} className='eventCTABtns'>
              {Object.keys(ctas).map((ctaName) => {
                if (ctas[ctaName]) {
                  return (
                    <Link
                      sx={styles.eventCTA}
                      href={ctas[ctaName]}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {ctaName}
                    </Link>
                  )
                }
              })}
            </Flex>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    ''
  )
}

const styles = {
  // ?============================
  // ?======  Modal Styles =======
  // ?============================

  modalContainer: {
    position: 'fixed',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    zIndex: '9999',
  },

  modalBackground: {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },

  modal: {
    padding: 0,
    maxWidth: '767px',
    zIndex: '9999',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    color: 'black',
    backgroundColor: 'white',
    minWidth: '280px',
    border: 'solid 6px white',
  },

  modalImage: {
    maxHeight: ['175px', '250px', '500px'],
    width: '100%',
    objectFit: 'cover',
  },

  modalContentContainer: {
    padding: 3,
    color: 'white',
  },

  dateNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    borderBottom: 'solid 0.5px lightgrey',
    paddingBottom: '1rem',
    '.eventItemDateContainer': {
      // padding: '0rem',
      backgroundColor: '#ebebea',
      marginRight: '1rem',
    },
  },

  modalEventName: {
    fontFamily: 'heading',
    fontWeight: 'normal',
    lineHeight: 'heading',
    textTransform: 'capitalize',
    fontSize: 5,
    marginBottom: 3,
    color: 'text',
    textAlign: 'left',
  },

  modalEventDate: {
    alignItems: 'center',
    marginBottom: 3,
    color: 'text',
  },

  modalEventTime: {
    fontSize: '1rem',
    color: 'grey',
  },

  datesSeperator: {
    color: 'text',
    marginRight: 2,
    marginLeft: 1,
  },
  modalEventDescription: {
    color: 'text',
    margin: '1.5rem 0rem',
  },

  eventCTAS: { flexWrap: 'wrap', marginBottom: '0.5rem' },
  eventCTA: {
    variant: 'buttons.primary',
    margin: '0.5rem 1rem 0.5rem 0rem',
    cursor: 'pointer',
    // ?== Psuedo Elements ==
    ':hover': {
      color: 'primary',
    },
  },
}
