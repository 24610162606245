import React from 'react'
import { Box, Image, Flex, Text, Button } from 'theme-ui'
import EventsModal from './EventsModal'
import EventDate from './EventDate'
import EventDays from './EventDays'

export default function EventItem({
  event,
  setEvents,
  closeModal,
  state,
  slug,
}) {
  const {
    _id,
    name,
    starts,
    //  ends,
    description,
    imageurl,
  } = event

  return (
    <Flex
      key={_id}
      sx={styles.eventItemContainer}
      className={`eventItemContainer ${_id}`}
    >
      <Box
        sx={styles.eventItemImageContainer}
        onClick={() => {
          setEvents({ ...state, modalShowing: _id })
        }}
      >
        <Image
          sx={styles.eventItemImage}
          src={imageurl}
          className='eventItemImage'
          alt='Event_Image'
        />
        <Box sx={styles.eventDateContainer}>
          {!event.eventDays && <EventDate date={starts} />}
        </Box>
      </Box>
      <Flex sx={styles.eventItemContent} className='eventItemContent'>
        <Text sx={styles.eventItemTitle} className='eventItemTitle'>
          {name}
        </Text>
        {event.eventDays && <EventDays eventDays={event.eventDays} />}
        <Text
          as='p'
          sx={styles.eventItemDescription}
          className='eventItemDescription'
        >
          {description.length > 80
            ? `${description.substring(0, 80)}...`
            : description}
        </Text>
      </Flex>
      {/* <Button
        sx={styles.eventItemBtn}
        className='eventItemBtn'
        onClick={() => {
          setEvents({ ...state, modalShowing: _id })
        }}
        sx={{ marginTop: 3, cursor: 'pointer' }}
      >
        See More
      </Button> */}

      <EventsModal
        slug={slug}
        event={event}
        modalShowing={_id === state.modalShowing}
        closeModal={closeModal}
      />
    </Flex>
  )
}

const styles = {
  // ?============================
  // ?======  Event Item  ========
  // ?============================

  eventItemContainer: {
    margin: ['1%'],
    width: ['100%', '48%', '31%', '23%', '18%'],
    flexDirection: 'column',
    flexGrow: '1',
    maxWidth: ['100%', '50%'],
    cursor: 'pointer',
  },
  eventItemImageContainer: {
    position: 'relative',
    display: 'flex',
  },

  eventDateContainer: {
    position: 'absolute',
    bottom: '0.25rem',
    left: '0.25rem',
    backgroundColor: '#ebebea',
  },

  eventItemImage: {
    height: '300px',
    width: '100%',
    objectFit: 'cover',
  },

  eventItemContent: {
    flexGrow: '1',
    padding: 2,
    flexDirection: ['column', 'column'],
  },

  eventItemTitle: {
    fontSize: 3,
    textTransform: 'capitalize',
    textAlign: 'left',
    fontWeight: '600',
    fontFamily: 'heading',
  },
  eventItemDescription: {
    width: '100%',
    fontSize: 1,
  },

  eventItemBtn: {
    variant: 'buttons.secondary',
  },
}
