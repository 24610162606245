export const weekends = ['saturday', 'sunday']
export const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
export const everyday = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
