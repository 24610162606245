import React, { useContext } from 'react';
import { Box, Flex, Text, Image, Heading } from 'theme-ui';
import { store } from '../../../context/store';
import cloudinaryString from '../../../helpers/cloudinaryString';

export default function NoEvents({ recurringEvents, singleEvents }) {
  const { globalState, dispatch } = useContext(store);

  const { businessData } = globalState;

  if (!singleEvents?.length && !recurringEvents?.length) {
    return (
      <>
        <Flex
          className="noEventsPosted"
          sx={{
            justifyContent: 'center',
            padding: '2rem 2rem 4rem',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box className="logoContainer">
            <Image
              className="logo"
              sx={{ maxWidth: '250px', marginBottom: '2rem' }}
              src={cloudinaryString(
                businessData.avatar.imageBaseUrl,
                businessData.avatar.imagePrefix
              )}
            />
          </Box>
          <Heading className="noEventsPostedTitle" as="h4">
            No Events Posted,
          </Heading>

          <Heading
            className="noEventsPostedSubtitle"
            as="h5"
            sx={{ marginTop: '1rem', opacity: '0.7' }}
          >
            Please Check Back Again Later.
          </Heading>
        </Flex>
      </>
    );
  }
  return '';
}

// {
//   /* {(!recurringEvents?.length && !singleEvents?.length) ||
//       !singleEvents?.length ? ( */
// }
