import React from 'react'
import { Text, Flex } from 'theme-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import recurringEventDaysReducer from '../EventFunctions/recurringEventDaysReducer'

export default function EventDays({ eventDays, variantName }) {
  let typeOfEvent = recurringEventDaysReducer(eventDays)

  return (
    <Flex sx={styles.eventDaysContainer} className='eventDaysContainer'>
      <FontAwesomeIcon
        style={{ marginRight: '10px', fontSize: '22px' }}
        icon={faCalendarAlt}
      />
      <Text sx={styles.eventDays} as='span' className='eventDays'>
        {typeOfEvent}
      </Text>
    </Flex>
  )
}

const styles = {
  eventDaysContainer: {
    margin: '0.5rem 0rem',
  },

  eventDays: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
}
